import {AnyAction} from 'redux'
import {RsvpStatus} from '@wix/events-types'
import {RsvpState} from '../types'
import {RSVP_STEPS} from '../constants/constants'
import {SET_RSVP_DETAILS, NEXT_RSVP_STEP, EDIT_RSVP_STEP, CHANGE_RESPONSE, SEND_RSVP} from '../actions/rsvp'

const defaultState: RsvpState = {
  currentStep: RSVP_STEPS[0],
  rsvpDetails: null,
  response: RsvpStatus.YES,
}

export const rsvp = (state = defaultState, action: AnyAction): RsvpState => {
  switch (action.type) {
    case SET_RSVP_DETAILS:
      return {
        ...state,
        rsvpDetails: action.payload.rsvpDetails,
      }
    case NEXT_RSVP_STEP:
      return {
        ...state,
        currentStep: action.payload.nextStep,
      }
    case EDIT_RSVP_STEP:
      const {currentStep} = action.payload
      return {
        ...state,
        currentStep,
      }
    case CHANGE_RESPONSE:
      return {
        ...state,
        response: action.payload.response,
      }
    case SEND_RSVP.FAILURE:
      return {
        ...state,
        currentStep: RSVP_STEPS[0],
      }
    default:
      return state
  }
}
